<template>
    <v-container>
        <v-card slot="rightContainer" outlined>
            <v-card-title class="grey lighten-4">
                <b>HISTORICO ORDENES DE TRABAJO</b>
                <v-spacer />
                <v-col cols="12" sm="3">
                    <v-menu ref="menu" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="auto">
                        <template #activator="{ on, attrs }">
                            <v-text-field v-model="fechaFiltro" hide-details="auto" dense label="FILTRAR POR RANGO - MES"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                        </template>
                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                            <v-spacer />
                            <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            <v-btn color="primary" :loading="loadingLista" @click="getLista"> BUSCAR </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line
                        hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :items="listaOEqui" :headers="headers" class="elevation-1"
                            :items-per-page="20">
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="orange lighten-2" small
                                            @click="calltoprint(item)">mdi-factory</v-icon>
                                    </template>
                                    <span>Descargar</span>

                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="red" small
                                            @click="closePosition(item)">mdi-clock-in</v-icon>
                                    </template>
                                    <span>Cerrar Orden</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip dark>
                                    {{ item.status == 1 ? 'ACTIVE' : 'CERRADA' }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { format, subDays, addDays, parseJSON } from 'date-fns'

export default {
    async mounted() {
        await this.getLista()
    },
    data() {
        return {
            ordenEquipamiento: [],
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu3: false,
            loadingLista: false,
            dataProducto: '',
            search: '',
            headers: [
                { text: '', value: 'action', sortable: false },
                { text: 'ID', value: 'id' },
                { text: 'status', value: 'status' },

                { text: 'fechaTermino', value: 'fechaTermino' },
                { text: 'created_at', value: 'created_at' },
                { text: 'personalCargo', value: 'personalCargo' },

                { text: 'items', value: 'items' },
                { text: 'proyecto', value: 'proyecto' },
            ]
        }
    },
    computed: {
        listaOEqui() {
            let newLista = []
            if (this.ordenEquipamiento.length > 0) {
                this.ordenEquipamiento.map((x) => {
                    const it = JSON.parse(x.items)
                    const list = it.map(x => x.name)
                    newLista.push({
                        ...x,
                        items: it ? list.join(', ') : null
                    })
                })
            }
            // console.log('newLista', newLista)

            return newLista
        }
    },
    methods: {
        async calltoprint(item) {
            console.log('itemAQUIIII--------------------', item)
            await this.$store.dispatch('inventario/getReport', {
                orden: item.id,
                fechaTermino: item.fechaTermino,
                proyecto: item.proyecto,
                personacargo: item.personalCargo,
                items: item.items
            })

        },
        async getLista() {
            this.loadingLista = true
            const { data } = await this.$store.dispatch('inventario/getListOT', { fechaFiltro: this.fechaFiltro })
            this.ordenEquipamiento = data
            this.loadingLista = false
            this.menu3 = false
        },

        async closePosition(item) {
            console.log('itemAQUIIII--------------------', item)
            await this.$store.dispatch('inventario/putListOT', {
                data: {
                    status: !item.status
                },
                id: item.id
            })
            await this.getLista()

        }
    },

}
</script>
